<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-uppercase">
          Номер телефона
        </th>
        <th class="text-center text-uppercase">
          Причина
        </th>
        <th class="text-center text-uppercase">
          Статус
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
        v-for="(item, index) in notifications"
        :key="index"
      >
        <td class="text-center">
          {{ item.phoneNumber }}
        </td>
        <td class="text-center">
          {{ item.reason }}
        </td>
        <td class="text-center">
          {{ item.status }}
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'PushNotificationHistory',
  computed: {
    ...mapState('pushNotificationHistory', {
      notifications: 'list',
    }),
  },
  methods: {
    ...mapActions('pushNotificationHistory', {
      fetch: 'fetchList',
    }),
  },
  async created() {
    const { id } = this.$route.params
    document.title = 'Пуш уведомления'
    await this.fetch(id)
  },

}
</script>

